<template>
  <v-btn icon class="mx-2" @click.stop="toggleDrawer">
    <v-icon v-html="drawerCollapsed ? 'menu_open' : 'menu'"></v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DRAWER_COLLAPSED } from "@/store/constants/actions.type";

export default {
  computed: {
    ...mapGetters(["drawerCollapsed"])
  },
  methods: {
    ...mapActions({
      toggleDrawer: DRAWER_COLLAPSED
    })
  }
};
</script>
